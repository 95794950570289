import React from 'react';
import 'animate.css';
import './styles/styles.css';
import { useState, useEffect } from 'react';
import { Row, Col, Skeleton } from "antd";
import { API_ROOT } from '../../../utils/global-var';
import { fetchImageUrl } from '../../../utils/fetchImageUrl';
import { Link } from 'react-router-dom';
import he from 'he';

export default function ProjectPage() {
    const [projects, setProjects] = useState([]);


    async function fetchProjects() {
        const res = await fetch(`${API_ROOT}/projects?_fields=id,slug,title,content,status,featured_media`, { cache: 'no-store' });
        const data = await res.json();

        const projectsWithImages = await Promise.all(data.map(async (project) => {
            const imageUrl = await fetchImageUrl(project.featured_media);
            return {
                ...project,
                image: imageUrl
            };
        }));
        setProjects(projectsWithImages);
    }


    useEffect(() => {
        fetchProjects();
    }, []);

    return (
        <div className="project-page animate__animated animate__fadeIn container">
            <h2>Projects</h2>
            <div className="divider" />
            <div className="content">
                {
                    projects.length > 0 ?
                        <Row gutter={24}>
                            {projects.map((item, index) => (
                                <Col lg={4} xs={24} key={index}>
                                    <div className="blc animate__animated animate__fadeIn">
                                        <Link to={`/projects/${item.slug}`}>
                                            <div className="img" style={{ backgroundImage: `url(${item.image || '/default-image.jpg'})` }} />
                                            <h4>{he.decode(item.title.rendered)}</h4>
                                        </Link>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                        : <Skeleton />}
            </div>
        </div>
    );
}
