import React from 'react';
import 'animate.css';
import './styles/styles.css';
import { useState, useEffect } from 'react';
import { Row, Col, Skeleton } from "antd";
import { API_ROOT } from '../../../utils/global-var';
import { fetchImageUrl } from '../../../utils/fetchImageUrl';
import { NavLink, useParams } from 'react-router-dom';
import he from 'he';

export default function ProjectDetailPage() {
    const [project, setProject] = useState();
    const { slug } = useParams();

    async function fetchProjects() {
        const res = await fetch(`${API_ROOT}/projects?slug=${slug}`, { cache: 'no-store' });
        const data = await res.json();
        const projectsWithImages = await Promise.all(data.map(async (project) => {
            const imageUrl = await fetchImageUrl(project.featured_media);
            return {
                ...project,
                image: imageUrl
            };
        }));
        setProject(projectsWithImages[0]);
    }


    useEffect(() => {
        fetchProjects();
    }, []);


    return (
        <div className="detail-page">
            <div className='container'>
                <h2 className="title">
                    <NavLink to={"/projects"}>Projets & Réalisations: </NavLink> <span>Aperçu</span></h2>
                <div className="divider" />
                {
                    project ?
                        <div className="content animate__animated animate__fadeIn">
                            <Row gutter={24}>
                                <Col lg={5} xs={24}>
                                    <div className="blc">
                                        <div
                                            className="img"
                                            style={{ backgroundImage: `url(${project.image})` }}
                                            aria-label="Image du projet"
                                        />
                                    </div>
                                </Col>

                                <Col lg={15} xs={24}>
                                    <div className="blc-content">
                                        <h3 className="project-title">{he.decode(project.title?.rendered)}</h3>
                                        <div className="project-desc">
                                            <div dangerouslySetInnerHTML={{ __html: project.content?.rendered }} />
                                        </div>
                                        <br />
                                        <Row gutter={24}>
                                            {project.acf.reference_ios &&
                                                <a href={project.acf.reference_ios} target="_blank" rel="noopener noreferrer">
                                                    <img src="/icons-app_store.png" className="icon" alt="Lien App Store" />
                                                </a>
                                            }

                                            {project.acf.reference_android && (
                                                <a href={project.acf.reference_android} target="_blank" rel="noopener noreferrer">
                                                    <img src="/icons-google_play.png" className="icon" alt="Lien Google Play" />
                                                </a>
                                            )}

                                            {project.acf.reference_web && (
                                                <a href={project.acf.reference_web} target="_blank" rel="noopener noreferrer">
                                                    <img src="/icons-internet.png" className="icon" alt="Lien Web" />
                                                </a>
                                            )}
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <br />

                            {project?.acf.photo_gallery?.gallery.length > 0 &&
                                <div>
                                    <h3>Captures d’écran:</h3>
                                    <div className="divider" />
                                    <Row gutter={24} className="gallery">
                                        {project?.acf?.photo_gallery?.gallery.map((items) => (
                                            items.map((item) => (
                                                <Col lg={6} key={item.id}>sss
                                                    <div className="gallery-blc animate__animated animate__fadeIn">
                                                        <img src={item.full_image_url} className="gallery-img" alt={item.title} />
                                                    </div>
                                                </Col>
                                            ))
                                        ))}
                                    </Row>
                                </div>
                            }
                        </div> : <Skeleton />
                }

            </div>
        </div>
    );
}
